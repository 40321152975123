@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Bd-TRIAL.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-BdIt-TRIAL.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Bk-TRIAL.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-BkIt-TRIAL.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-It-TRIAL.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Lt-TRIAL.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-LtIt-TRIAL.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Md-TRIAL.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-MdIt-TRIAL.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Rg-TRIAL.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Smbd-TRIAL.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-SmbdIt-TRIAL.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Xbd-TRIAL.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-XbdIt-TRIAL.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-Xlt-TRIAL.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ESKlarheitGroteskMono';
  src: url('/public/fonts/ESKlarheitGroteskMono-XltIt-TRIAL.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

.orbitron-400 {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.orbitron-500 {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.orbitron-600 {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.orbitron-700 {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.orbitron-800 {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.orbitron-900 {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

/* Example usage */
body {
  font-family: 'ESKlarheitGroteskMono', sans-serif;
}

*{
  scrollbar-width: thin;
  scrollbar-color: #3A8AE8 #ECF2F6;
}

.inner-scroll{
  scrollbar-width: thin;
  scrollbar-color: #3A8AE8 #ECF2F6;
}